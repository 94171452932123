@tailwind base;
@tailwind components;
@tailwind utilities;

body *{
  letter-spacing: normal;
}

@font-face {
  font-family: "arab";
  src: url(./fonts/arabswell_1.ttf);
}

@font-face {
  font-family: "GothamBook", sans-serif;
  src: url("./fonts/gotham-book-webfont.woff"), format("woff"),
    url("./fonts/gotham-book-webfont.woff2"), format("woff2"),
    url("./fonts/gotham-book-webfont.ttf"), format("truetype");
    font-weight: 400;
}

@font-face {
  font-family: "GothamBold", sans-serif;
  src: url("./fonts/gotham-book-webfont.woff"), format("woff"),
  url("./fonts/gotham-book-webfont.woff2"), format("woff2"),
  url("./fonts/gotham-book-webfont.ttf"), format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "GothamBookItalic", sans-serif;
  src: url("./fonts/gotham-book-webfont.woff"), format("woff"),
  url("./fonts/gotham-book-webfont.woff2"), format("woff2"),
  url("./fonts/gotham-book-webfont.ttf"), format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "GothamMedium", sans-serif;
  src: url("./fonts/gotham-book-webfont.woff"), format("woff"),
  url("./fonts/gotham-book-webfont.woff2"), format("woff2"),
  url("./fonts/gotham-book-webfont.ttf"), format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "GothamLight", sans-serif;
    src: url("./fonts/gotham-book-webfont.woff"), format("woff"),
    url("./fonts/gotham-book-webfont.woff2"), format("woff2"),
    url("./fonts/gotham-book-webfont.ttf"), format("truetype");
    font-weight: 300;
}

@font-face {
  font-family: "moroccan-font";
  src: url("./fonts/maghribi-font\ 1.ttf");
}

#app-container {
  width: 100%;
  background-image: url('/public/images/bg.png');
  background-size: cover;
  background-repeat: repeat;
}