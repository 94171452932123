 /* CSS Styles for Articles */
 .article {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f5f5f5;
  }

  .article h2 {
    color: #333;
    font-size: 20px;
    margin-bottom: 5px;
  }
  
  .article p {
    color: #666;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .announcement {
    background-color: #ebf2ff;
    border: 2px solid rgba(27,127,204,.2);
    padding: 20px;
    border-radius: 5px;
    margin-top: 50px;
    text-align: center;
  }
  
  .announcement-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .announcement-text {
    font-size: 18px;
  }
  
  .announcement-text a {
    color: #007bff;
    text-decoration: none;
  }
  
  .announcement-text a:hover {
    text-decoration: underline;
  }
  