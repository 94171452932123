.scroll::-webkit-scrollbar {
    width: 10px;
}

.scroll::-webkit-scrollbar-track {
    background: #c9c9c9;
    border-radius: 5px;
}

.scroll::-webkit-scrollbar-thumb {
    background: #898989;
    /* border: solid #000000 2px; */
    border-radius: 20px;
}