.avatars {
    display: inline-flex;
    flex-direction: row-reverse;
}

.avatar {
    position: relative;
    border: 4px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    width: 50px;
}

.avatar:not(:last-child) {
    margin-left: -30px;
}

.avatar img {
    width: 100%;
    display: block;
}