.header-slider .slick-list,
.header-slider .slick-track {
    width: 100%;
    height: 100%;
}


.header-slider .slick-track{
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
}

.header-slider .slide-child{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.header-slider .slick-slide > div{
    height: 100%;
}

.header-slider .slick-arrow::before{
    color: rgb(90, 90, 90);
    font-size: 35px;
}

.header-slider .slick-arrow.slick-next{
    right: 20px !important;
    z-index: 10 !important;
}

.header-slider .slick-arrow.slick-prev{
    left: 0px !important;
    z-index: 10 !important;
}

.autoimgwidth{
    max-width: 100%;
    width: 100%;
}
.autoimgwidth img{
    max-width: 100%;
    width: 100%;
}