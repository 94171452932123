.sponsors .slick-track{
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.sponsors .slide-child{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.sponsors .slick-arrow::before{
    color: rgb(90, 90, 90);
    font-size: 35px;
}

.sponsors .slick-arrow.slick-next{
    right: 20px !important;
}

.sponsors .slick-arrow.slick-prev{
    left: 0px !important;
    z-index: 10;
}

/* .sponsors .slick-center {
    transition: scale 0.5s;
    scale: 2;
} */