.about::-webkit-scrollbar{
    width: 10px;
  }
  
.about::-webkit-scrollbar-track {
    background: #383a3d;
  }
  
.about::-webkit-scrollbar-thumb {
    background: var(--primary);
    /* border: solid #000000 2px; */
    border-radius: 20px;
  }