@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400&family=Signika:wght@300;400;500;600;700&display=swap');
/* RTL Container */
.rtl-container {
    direction: rtl;
    font-family: 'Cairo', sans-serif !important;
  }
  
  /* RTL Text */
  .rtl-text {
    text-align: right;
  }
  
  /* RTL List */
  .rtl-list {
    padding-right: 10px;
  }
  
  /* RTL Margins */
  .rtl-margin {
    margin-right: 10px;
  }
  
  /* RTL Paddings */
  .rtl-padding {
    padding-right: 10px;
  }
  
  /* RTL Float Right */
  .rtl-float-right {
    float: right;
  }
  
  /* RTL Float Left */
  .rtl-float-left {
    float: left;
  }
  
  /* RTL Flexbox */
  .rtl-flex-container {
    flex-direction: row-reverse;
  }
  
  /* RTL Grid */
  .rtl-grid-container {
    direction: rtl;
  }
  
  /* RTL Form */
  .rtl-form-input {
    text-align: right;
  }
  
  /* RTL Modal */
  .rtl-modal-content {
    direction: rtl;
  }
  
  /* RTL Table */
  .rtl-table {
    direction: rtl;
    text-align: right;
  }
  
  /* RTL Button */
  .rtl-button {
    padding: 5px 10px;
    text-align: right;
  }
  