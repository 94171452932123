.radio-btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.special-dietary{
    display: flex;
    flex-wrap: wrap;
}
.special-dietary label {
    margin-left: 25px;
}
.book-form .wrapper {
    max-width: 1050px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    display: flex;
    margin: 50px auto;
    box-shadow: 0 8px 20px 0px #1f1f1f 1a;
    overflow: hidden;
}

.book-form .wrapper .form-left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 0;
    position: relative;
    width: 100%;
    color: #fff;
}

.book-form .wrapper h2 {
    font-weight: 700;
    font-size: 25px;
    padding: 5px 0 0;
    margin-bottom: 34px;
    pointer-events: none;
}

.book-form .wrapper .form-left p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    pointer-events: none;
}

.book-form .wrapper .form-left .text {
    margin: 20px 0 25px;
}

.book-form .wrapper .form-left p span {
    font-weight: 700;
}

.book-form .wrapper .form-left input {
    padding: 15px;
    background: #fff;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 180px;
    border: none;
    margin: 15px 0 50px 0px;
    cursor: pointer;
    color: #333;
    font-weight: 700;
    font-size: 0.9rem;
    appearance: unset;
    outline: none;
}

.book-form .wrapper .form-left input:hover {
    background-color: #f2f2f2;
}

.book-form .wrapper .form-right {
    padding: 20px 40px;
    position: relative;
    width: 100%;
}

.book-form .wrapper .form-right h2 {
    color: #3786bd;
}

.book-form .wrapper .form-right label {
    font-weight: 600;
    font-size: 15px;
    color: #666;
    display: block;
    margin-bottom: 8px;
}

.book-form .wrapper .form-right .input-field {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    outline: none;
    color: #333;
}

.book-form .wrapper .form-right .input-field:focus {
    border: 1px solid #31a031;
}

.book-form #img-booking {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.book-form #booking-left-content {
    position: relative;
    z-index: 2;
    background-color: #00000057;
    padding: 30px 25px;
    height: 100%;
}

.book-form .wrapper .option {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    font-size: 0.95rem;
    font-weight: 900;
    cursor: pointer;
    user-select: none;
}

.book-form .wrapper .option input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.book-form .wrapper .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 2px;
}

.book-form .wrapper .option:hover input~.checkmark {
    background-color: #f1f1f1;
}

.book-form .wrapper .option input:checked~.checkmark {
    border: 2px solid #e5e5e5;
    background-color: #fff;
    transition: 300ms ease-in-out all;
}

.book-form .wrapper .checkmark:after {
    content: "\2713";
    position: absolute;
    display: none;
    color: #3786bd;
    font-size: 1rem;
}

.book-form .wrapper .option input:checked~.checkmark:after {
    display: block;
}

.book-form .wrapper .option .checkmark:after {
    left: 2px;
    top: -4px;
    width: 5px;
    height: 10px;
}

.book-form .wrapper .register {
    padding: 12px;
    background: #3786bd;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 130px;
    border: none;
    margin: 6px 0 50px 0px;
    cursor: pointer;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
}

.book-form .wrapper .register:hover {
    background-color: #3785bd e0;
}

.book-form .wrapper a {
    text-decoration: none;
}

@media (max-width: 860.5px) {
    .book-form .wrapper {
        margin: 50px 5px;
    }
}

@media (max-width: 767.5px) {
    .book-form .wrapper {
        flex-direction: column;
        margin: 30px 20px;
    }

    .book-form .wrapper .form-left {
        border-bottom-left-radius: 0px;
    }
}

@media (max-width: 575px) {
    .book-form .wrapper {
        margin: 30px 15px;
    }

    .book-form .wrapper .form-left {
        padding: 25px;
    }

    .book-form .wrapper .form-right {
        padding: 25px;
    }
}