#testimonials .slick-slide {
    transition: scale .3s, translate .3s;
}

@media (min-width: 1024px) {
    #testimonials .slick-center {
        position: relative;
        scale: 1.15 !important;
        translate: 0% 0%;
        z-index: 20;
    }

    #testimonials .slick-list {
        overflow: visible;
        overflow-y: visible !important;
        overflow-x: visible;
    }
}