.desktop-menu a,
.mobile-menu a {
    position: relative;
    /* overflow: hidden; */
    transition: color .3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop-menu a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    translate: 0% 100%;
    width: 0%;
    height: 4px;
    background-color: #F4AE3F;
    transition: width .3s, translate 1s;
}

.desktop-menu a:hover,
.mobile-menu a:not(.active-link):hover {
    color: #F4AE3F !important;
}

.desktop-menu a:hover::after {
    width: 100%;
    translate: 0;
    transition: width .3s, translate .1s;
}

.desktop-menu .active-link::after {
    width: 100% !important;
    translate: 0;
    transition: height .3s !important;
}

.mobile-menu .active-link {
    background-color: #F4AE3F !important;
    color: white;
    font-weight: bold;
}

/* .active-link::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #F4AE3F;
    width: 100% !important;
    height: 5px !important;
    translate: 0 -100% !important;
    transition: width .3s !important;
} */


.menu-burger {
    position: relative;
    z-index: 30;
    width: 50px;
    height: 36px;
    padding: 5px;
    /* display: flex; */
    flex-direction: column;
    align-items: flex-end;
    /* gap: 8px; */
    cursor: pointer;
}

.menu-burger .bar:not(.menu-burger .bar:first-child) {
    margin-top: 8px;
}

.menu-burger .bar {
    background-color: #185081;
    border-radius: 2px;
}

.menu-burger .bar:nth-child(1) {
    height: 4px;
    width: 100%;
    transition: translate 0.3s 0.3s, rotate 0.3s, width 0.3s;
}

.menu-burger .bar:nth-child(2) {
    height: 3px;
    width: 75%;
    transition: width 0.3s, opacity 0.3s;
}

.menu-burger .bar:nth-child(3) {
    height: 4px;
    width: 50%;
    transition: translate 0.3s 0.3s, rotate 0.3s, width 0.3s;
}

.menu-burger:hover .bar {
    width: 100%;
}

.menu-open .bar:nth-child(1) {
    translate: 0 10.5px !important;
    rotate: 45deg;
    transition: translate 0.3s, rotate 0.3s 0.3s, width 0.3s;
}

.menu-open .bar:nth-child(2) {
    opacity: 0;
}

.menu-open .bar:nth-child(3) {
    translate: 0 -12px !important;
    rotate: -45deg;
    width: 100%;
    transition: translate 0.3s, rotate 0.3s 0.3s, width 0.3s;
}

.bar-open {
    translate: 0 200px !important;
    background-color: aqua !important;
}

.submenu:hover div {
    display: flex;
}
.submenu a:hover::after{
    display: none;
}