.sticky-button {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: #57b17e;
    color: #fff;
    padding: 12px 20px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    z-index: 100000000000000000;
  }
  
  @media (max-width: 768px) {
    .sticky-button {
      font-size: 16px;
      padding: 10px 15px;
    }
  }