@font-face {
    font-family: "GothamBook" ;
    src: url("../../fonts/GothamBook.ttf");
}

@font-face {
    font-family: "GothamLight";
    src: url("../../fonts//GothamLight.ttf");
}

@font-face {
    font-family: "GothamBoldItalic";
    src: url("../../fonts/GothamBoldItalic.ttf");
}

/* .goal-1{
    filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.745));
} */