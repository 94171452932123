.wrapper{
    max-width: 850px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    display: flex;
    margin: 50px auto;
    box-shadow: 0 8px 20px 0px #1f1f1f1a;
    overflow: hidden;
}

.wrapper .form-left{
 
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 20px 40px;
    position: relative;
    width: 100%;
    color: #fff;
}

.wrapper h2{
    font-weight: 700;
    font-size: 25px;
    padding: 5px 0 0;
    margin-bottom: 34px;
    pointer-events: none;
}

.form-left .main-list {
    list-style-type: disc;
    list-style-position: inside;
    font-size: 16px;
}

.form-left .main-list .main-li {
    line-height: 30px;
}

.main-list .inside-list {
    padding-left: 30px;
    list-style-position: inside;
}

.tarif-list {
    padding-left: 30px;
}

.wrapper .form-left .exposant-text{
    font-size: 1rem;
    font-weight:300 ;
    line-height: 1.5;
    margin-bottom: 10px;
    pointer-events: none;
}

/* .wrapper .form-left .text{
    margin: 20px 0 25px;
} */

/* .wrapper .form-left p span{
    font-weight: 700;
} */

.wrapper .form-left input{
    padding: 15px;
    background: #fff;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 180px;
    border: none;
    margin: 15px 0 50px 0px;
    cursor: pointer;
    color: #333;
    font-weight: 700;
    font-size: 0.9rem;
    appearance: unset;
    outline: none;
}

.wrapper .form-left input:hover{
    background-color: #f2f2f2;
}

.wrapper .form-right{
    padding: 20px 40px;
    position: relative;
    width: 100%;
}

.wrapper .form-right h2{
    color: #3786bd;
}

.wrapper .form-right label{
    font-weight: 600;
    font-size: 15px;
    color: #666;
    display: block;
    margin-bottom: 8px;
}

.wrapper .form-right .input-field{
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    outline: none;
    color: #333;
}

.wrapper .form-right .input-field:focus{
    border: 1px solid #31a031;
}


.wrapper .option {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    font-size: 0.95rem;
    font-weight: 900;
    cursor: pointer;
    user-select: none
}

.wrapper .option input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.wrapper .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 2px
}

.wrapper .option:hover input~.checkmark {
    background-color: #f1f1f1
}

.wrapper .option input:checked~.checkmark {
    border: 2px solid #e5e5e5;
    background-color: #fff;
    transition: 300ms ease-in-out all
}

.wrapper .checkmark:after {
    content: "\2713";
    position: absolute;
    display: none;
    color: #3786bd;
    font-size: 1rem;
}

.wrapper .option input:checked~.checkmark:after {
    display: block
}

.wrapper .option .checkmark:after {
    left: 2px;
    top: -4px;
    width: 5px;
    height: 10px
}

.wrapper .register{
    padding: 12px;
    background: #3786bd;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 130px;
    border: none;
    margin: 6px 0 50px 0px;
    cursor: pointer;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
}

.wrapper .register:hover{
    background-color: #3785bde0;
}

.wrapper a{
    text-decoration: none;
}

@media (max-width: 860.5px) {
    .wrapper{
        margin: 50px 5px;
    }
}


@media (max-width: 767.5px){
    .wrapper{
        flex-direction: column;
        margin: 30px 20px;
    }

    .wrapper .form-left{
        border-bottom-left-radius: 0px;
    }

    
}

@media (max-width: 575px) {

    .wrapper{
        margin: 30px 15px;
    }

    .wrapper .form-left{
        padding: 25px;
    }
    .wrapper .form-right{
        padding: 25px;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }