@font-face {
    font-family: "GothamBook";
    src: url("../../fonts//GothamBook.ttf");
}

@font-face {
    font-family: "GothamBookItalic";
    src: url("../../fonts//GothamBoldItalic.ttf");
}

@font-face {
    font-family: "GothamBold";
    src: url("../../fonts/GothamBold.ttf");
}

@font-face {
    font-family: "GothamBoldItalic";
    src: url("../../fonts//GothamBoldItalic.ttf");
}

@font-face {
    font-family: "GothamMedium";
    src: url("../../fonts/GothamMedium.ttf");
}

@font-face {
    font-family: "GothamLight";
    src: url("../../fonts//GothamLight.ttf");
}

@font-face {
    font-family: "GothamLightItalic";
    src: url("../../fonts//GothamLightItalic.ttf");
}



.bootstrap .card {
    max-width: 400px;
    margin: 0 auto;
}

.speakers {}

.speakers .slick-track {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.speakers .slide-child {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.speakers .slick-arrow::before {
    color: rgb(90, 90, 90);
    font-size: 35px;
}

.speakers .slick-arrow.slick-next {
    right: 40px !important;
}

.speakers .slick-arrow.slick-prev {
    left: 20px !important;
    z-index: 10;
}


.flexdisheadertext {
    font-family: "GothamBook";
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 25px;
}

.flexdisheadertext span a {
    text-decoration: none;
}

.ml-1 {
    margin-left: 10px;
}

.btnyellow {
    background-color: #F4AE3F;
    border-radius: 20px;
    color: white;
    box-shadow: black;
    text-align: center;
    padding: 5px 20px;
    width: 150px;
    font-weight: 600;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.33);
}

.btntransparent {
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 20px;
    box-shadow: black;
    text-align: center;
    padding: 5px 20px;
    width: 150px;
    font-weight: 600;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.33);
}

.icocls {
    font-size: 4em;
    color: #000000aa;
}

.icoparent {
    font-family: "GothamLight";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icoparent p {
    margin-top: 10px;
}

.headerstyle {
    /* letter-spacing: 7px; */
    font-weight: 500;
    font-size: 3em;
    text-align: center;
}

.descstyle {
    width: 80%;
    text-align: center;
    font-size: 18px;
}

/* section Media */
.MediaTitle {
    font-family: "GothamMedium";
    margin-bottom: 45px;
}

.media-videos {
    box-sizing: border-box;
}

.bigfont {
    font-size: 40px;
    color: #2375BB;
}

.bigfont .meeting {
    display: flex;
    font-weight: 700;
    text-align: center;
}

.meeting2 {
    display: flex;
    align-items: center;
    gap: 20px;
}

.meeting2 .date {
    width: 100%;
    text-align: center;
}

.meeting2 .line {
    display: flex;
    width: 100%;
    height: 2px;
    background-color: #F3AE3E;
}

.speaker {
    padding: 0 10px;
    margin-top: 60px;
}

.overcard:first-child {
    margin-bottom: 70px;
    
}

.card {
    position: relative;
    padding-top: 60px;
}

.circle-img {
    object-fit: cover;
    object-position: top;
    position: absolute;
    top: 0;
    left: 50%;
    translate: -50% -50%;
    width: 120px !important;
    height: 120px !important;
    box-shadow: 5px 5px 5px 2px rgba(163, 163, 163, 0.25);
    border-radius: 50%;
}

.card-title {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.card-text {
    font-size: 14px !important;
    font-weight: 300;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .bigfont {
        font-size: 2em;
        text-align: center;
        width: 100%;
    }

    .meeting2 .line {
        display: none;
    }

    .flexdisheadertext {
        flex-direction: column;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .bootstrap p {
        font-size: 1em !important;
    }




    .cardd {
        height: 200px !important;
    }


    .cardbodypad {
        padding-top: 20px !important;
    }
}

.ytbthubm {
    margin: 0 auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .ytbthubm {
        width: 270px !important;
        height: 220px !important;
    }

    .media-videos {
        padding: 0 5% !important;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .ytbthubm {
        width: 200px !important;
        height: 200px !important;
    }


}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {


    .ytbthubm {
        width: 300px !important;
        height: 300px !important;
    }

    .main-vid {
        height: 600px !important;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .ytbthubm {
        width: 361px !important;
        height: 361px !important;
    }
}

.clr {
    color: #2476bb;
    font-weight: 600;
}